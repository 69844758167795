import { elasticClient, elasticVectorClient } from "./client";
import { SearchData, SearchSuggestions, AnalyticsResults } from "./types";
import { SUGGESTION_SEARCH_SIZE } from "./constants";
import { getSearchData } from "./vectorSearchRequest";
import { VectorSearchOptions, VectorSearchRawResults } from "./types-vectorsearch";

type PopularSearches = AnalyticsResults & {
  networkError: boolean;
};

export const w = typeof window !== "undefined" ? window : undefined;

export const getPopularSearchResult = async (): Promise<PopularSearches> => {
  try {
    const { results } = await elasticClient<AnalyticsResults>(
      "analytics/queries",
      undefined,
      "GET"
    );

    return {
      results,
      networkError: false,
    };
  } catch (e) {
    return {
      results: [],
      networkError: true,
    };
  }
};

export const getVectorSearchResult = async (query: string, options?: VectorSearchOptions) => {
  try {
    const requestBody = getSearchData(query, options);
    const response = await elasticVectorClient<VectorSearchRawResults>(JSON.stringify(requestBody));

    const result = {
      hits: response.hits.hits,
      total: response.hits.total,
      categories: response.aggregations.categories.buckets,
    };

    return {
      result,
      error: false,
    };
  } catch (e) {
    return {
      result: {
        categories: [],
        hits: [],
        total: {
          value: 0,
        },
      },
      error: true,
    };
  }
};

export const getSearchSuggestionResult = async (
  query: string,
  resultSize = SUGGESTION_SEARCH_SIZE
) => {
  if (query.trim().length === 0) {
    return { result: [], error: false };
  }

  try {
    const data = await elasticClient<SearchData<SearchSuggestions>>("query_suggestion", {
      query,
    });

    return {
      result: data.results
        ? data.results.documents
            .filter(({ suggestion }) => !suggestion.includes('"'))
            .map(({ suggestion }) => suggestion)
            .slice(0, resultSize)
        : [],
      error: false,
    };
  } catch (e) {
    return {
      result: [],
      error: true,
    };
  }
};
