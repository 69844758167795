import { useEffect, useState } from "react";
import throttle from "lodash.throttle";

import { type AnalyticsResult } from "./types";
import { getPopularSearchResult, getSearchSuggestionResult } from "./search";

export const useSearch = () => {
  const [popularSearches, setPopularSearches] = useState<AnalyticsResult[]>([]);
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
  const [searchSuggestionError, setSearchSuggestionError] = useState(false);

  useEffect(() => {
    (async () => {
      const { results } = await getPopularSearchResult();
      setPopularSearches(results);
    })();
  }, []);

  const suggest = throttle(async (query: string, resultSize?: number) => {
    const { result, error } = await getSearchSuggestionResult(query, resultSize);

    setSearchSuggestionError(error);
    setSearchSuggestions(result);
  }, 400);

  return {
    popularSearches,
    searchSuggestionError,
    searchSuggestions,
    suggest,
  };
};
