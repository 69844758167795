import { BASE_URL, VECTOR_BASE_URL } from "./constants";
import { ClickOptions, SearchOptions } from "./types";
import { fetchWithOptions } from "@telia/b2x-graphql-request";

export const elasticClient = async <T>(
  url: string,
  data?: SearchOptions | ClickOptions,
  method = "POST"
): Promise<T> => {
  const bodyData = data;

  const response = await fetchWithOptions(`${BASE_URL}/${url}`, {
    method,
    mode: "cors",
    cache: "no-cache",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    referrerPolicy: "no-referrer",
    body: method !== "GET" ? JSON.stringify(bodyData) : null,
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  const responseText = await response.text();
  return responseText.length ? JSON.parse(responseText) : null;
};

export const elasticVectorClient = async <T>(body: string): Promise<T> => {
  const response = await fetchWithOptions(`${VECTOR_BASE_URL}`, {
    method: "POST",
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    referrerPolicy: "no-referrer",
    body,
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  const responseText = await response.text();
  return responseText.length ? JSON.parse(responseText) : null;
};
