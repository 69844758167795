import { useState } from "react";

import { getVectorSearchResult } from "./search";
import { VectorSearchOptions, VectorSearchResults } from "./types-vectorsearch";

type OnSearchedCallback = (query: string, totalResults: number) => void;

const EMPTY_SEARCH = {
  categories: [],
  hits: [],
  total: {
    value: 0,
  },
};

export const useVectorSearch = (onSearched?: OnSearchedCallback) => {
  const [searchError, setSearchError] = useState(false);
  const [searchResult, setSearchResult] = useState<VectorSearchResults>(EMPTY_SEARCH);
  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const search = async (query: string, options?: VectorSearchOptions) => {
    if (!query) return;

    setIsLoading(true);
    setSearchResult(EMPTY_SEARCH);
    setSearchError(false);

    const { result, error } = await getVectorSearchResult(query, options);
    onSearched?.(query, result.total.value);
    setSearched(true);
    setSearchResult(result);
    setSearchError(error);
    setIsLoading(false);
    return result;
  };

  const reset = () => setSearched(false);

  return {
    isLoading,
    search,
    searched,
    searchError,
    searchResult,
    reset,
  };
};
