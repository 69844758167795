import endpoints from "@telia/base-rig/endpoints";

export const SUGGESTION_SEARCH_SIZE = 5;
export const SEGMENT_ALL_ID = "all";

export const BASE_URL =
  endpoints
    .auto()
    .replace(endpoints.localProd, endpoints.prod)
    .replace(endpoints.localTest, endpoints.dev)
    .replace(/\/\.api/g, "") + "/search/v1/engines/telia-se";

export const VECTOR_BASE_URL =
  endpoints
    .auto()
    .replace(endpoints.localProd, endpoints.prod)
    .replace(endpoints.localTest, endpoints.dev)
    .replace(/\/\.api/g, "") + "/vector-search/telia-se-v1,telia-se-legacy-v1/_search";

export const RESULT_FACETS = {
  category: [
    {
      type: "value",
      name: "total",
      sort: { count: "desc" },
    },
  ],
};

export const FACETS = ["category"] as const;

export enum Category {
  qa = "qa",
  guide = "guide",
  article = "article",
  product = "product",
  support = "support",
  fallback = "fallback",
}
